import React, { useState, useContext } from "react";
import fire from "../auth/fbAuth";
import { Link, withRouter, Redirect } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import Meta from "../components/layout/meta/Meta";
import { AuthContext } from "../auth/Auth";

function SignUp({ history }) {
  // Sign up process
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passConf, setPassConf] = useState("");
  const [universiti, setUniversiti] = useState("");

  const { addToast } = useToasts();

  const handleSignup = async (e) => {
    e.preventDefault();

    const noImg = "no-img.png";

    if (password !== passConf) {
      const message = "Password and password confirmation does not match";
      addToast(message, {
        appearance: "warning",
        autoDismiss: true,
      });
      return null;
    }

    await fire
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then((user) => {
        addToast("Successfully register", {
          appearance: "success",
          autoDismiss: true,
        });
        fire
          .firestore()
          .collection("users")
          .doc(user.user.uid)
          .set({
            firstName: firstName,
            lastName: lastName,
            email: email,
            educationInstitute: universiti,
            role: "student",
            userProfilePicture: `https://firebasestorage.googleapis.com/v0/b/kitashare-1653e.appspot.com/o/${noImg}?alt=media`,
            createdAt: new Date().toISOString(),
            agreement: true,
          });
        history.push("/student/dashboard");
      })
      .catch((err) => {
        const message = err.message;
        addToast(message, { appearance: "error", autoDismiss: true });
      });
    setFirstName("");
    setLastName("");
    setEmail("");
    setPassword("");
    setPassConf("");
    setUniversiti("");
  };

  const { currentUser } = useContext(AuthContext);

  if(currentUser) {
    return <Redirect to="/" />
  }

  return (
    <>
      <Meta title="Sign up as a student now! | KitaShare Web Application and OCR" />
      <section className="bg-gradient-to-b from-gray-100 to-white">
        <div className="max-w-6xl mx-auto px-4 sm:px-6">
          <div className="pt-32 pb-12 md:pt-40 md:pb-20">
            {/* Page header */}
            <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
              <h1 className="h1">
                Welcome. We exist to make entrepreneurism easier.
              </h1>
            </div>

            {/* Form */}
            <div className="max-w-sm mx-auto">
              <form onSubmit={handleSignup}>
                <div className="flex flex-wrap -mx-3 mb-4">
                  <div className="w-full md:w-1/2 px-3">
                    <label
                      className="block text-gray-800 text-sm font-medium mb-1"
                      htmlFor="name"
                    >
                      First Name <span className="text-red-600">*</span>
                    </label>
                    <input
                      id="firstName"
                      value={firstName}
                      onChange={({ target }) => setFirstName(target.value)}
                      aria-describedby="firstName-helper-text"
                      type="text"
                      className="form-input w-full text-gray-800"
                      placeholder="Rohaizad"
                      required
                    />
                  </div>
                  <div className="w-full md:w-1/2 px-3">
                    <label
                      className="block text-gray-800 text-sm font-medium mb-1"
                      htmlFor="name"
                    >
                      Last Name <span className="text-red-600">*</span>
                    </label>
                    <input
                      id="lastName"
                      value={lastName}
                      aria-describedby="lastName-helper-text"
                      onChange={({ target }) => setLastName(target.value)}
                      type="text"
                      className="form-input w-full text-gray-800"
                      placeholder="Maznan"
                      required
                    />
                  </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-4">
                  <div className="w-full px-3">
                    <label
                      className="block text-gray-800 text-sm font-medium mb-1"
                      htmlFor="name"
                    >
                      Email <span className="text-red-600">*</span>
                    </label>
                    <input
                      id="email"
                      value={email}
                      aria-describedby="email-helper-text"
                      onChange={({ target }) => setEmail(target.value)}
                      type="email"
                      className="form-input w-full text-gray-800"
                      placeholder="your-email@email.com"
                      required
                    />
                  </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-4">
                  <div className="w-full px-3">
                    <label
                      className="block text-gray-800 text-sm font-medium mb-1"
                      htmlFor="email"
                    >
                      Password <span className="text-red-600">*</span>
                    </label>
                    <input
                      id="password"
                      value={password}
                      aria-describedby="password-helper-text"
                      onChange={({ target }) => setPassword(target.value)}
                      type="password"
                      className="form-input w-full text-gray-800"
                      placeholder="Password"
                      required
                    />
                  </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-4">
                  <div className="w-full px-3">
                    <label
                      className="block text-gray-800 text-sm font-medium mb-1"
                      htmlFor="password"
                    >
                      Confirm Password <span className="text-red-600">*</span>
                    </label>
                    <input
                      id="passConf"
                      value={passConf}
                      aria-describedby="passConf-helper-text"
                      onChange={({ target }) => setPassConf(target.value)}
                      type="password"
                      className="form-input w-full text-gray-800"
                      placeholder="Confirm password"
                      required
                    />
                  </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-4">
                  <div className="w-full px-3">
                    <label
                      className="block text-gray-800 text-sm font-medium mb-1"
                      htmlFor="name"
                    >
                      I study at <span className="text-red-600">*</span>
                    </label>
                    <input
                      id="universiti"
                      value={universiti}
                      onChange={({ target }) => setUniversiti(target.value)}
                      type="text"
                      className="form-input w-full text-gray-800"
                      placeholder="e.g Universiti Teknologi Malaysia"
                      required
                    />
                  </div>
                </div>
                <div className="flex flex-wrap -mx-3 mt-6">
                  <div className="w-full px-3">
                    <button
                      type="submit"
                      className="btn text-white bg-blue-600 hover:bg-blue-700 w-full"
                    >
                      Sign up
                    </button>
                  </div>
                </div>
                <div className="text-sm text-gray-500 text-center mt-3">
                  By creating an account, you agree to the{" "}
                  <Link
                    to="/documentation/terms-and-condition"
                    className="underline"
                  >
                    terms & conditions
                  </Link>
                  , and our{" "}
                  <Link
                    to="/documentation/agreement-of-privacy"
                    className="underline"
                  >
                    privacy policy
                  </Link>
                  .
                </div>
              </form>
              <div className="flex items-center my-6">
                <div
                  className="border-t border-gray-300 flex-grow mr-3"
                  aria-hidden="true"
                ></div>
                <div className="text-gray-600 italic">Or</div>
                <div
                  className="border-t border-gray-300 flex-grow ml-3"
                  aria-hidden="true"
                ></div>
              </div>
              <form>
                {/* <div className="flex flex-wrap -mx-3 mb-3">
                  <div className="w-full px-3">
                    <button className="btn px-0 text-white bg-gray-900 hover:bg-gray-800 w-full relative flex items-center">
                      <svg
                        className="w-4 h-4 fill-current text-white opacity-75 flex-shrink-0 mx-4"
                        viewBox="0 0 16 16"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M7.95 0C3.578 0 0 3.578 0 7.95c0 3.479 2.286 6.46 5.466 7.553.397.1.497-.199.497-.397v-1.392c-2.187.497-2.683-.993-2.683-.993-.398-.895-.895-1.193-.895-1.193-.696-.497.1-.497.1-.497.795.1 1.192.795 1.192.795.696 1.292 1.888.895 2.286.696.1-.497.298-.895.497-1.093-1.79-.2-3.578-.895-3.578-3.975 0-.895.298-1.59.795-2.087-.1-.2-.397-.994.1-2.087 0 0 .695-.2 2.186.795a6.408 6.408 0 011.987-.299c.696 0 1.392.1 1.988.299 1.49-.994 2.186-.795 2.186-.795.398 1.093.199 1.888.1 2.087.496.596.795 1.291.795 2.087 0 3.08-1.889 3.677-3.677 3.875.298.398.596.895.596 1.59v2.187c0 .198.1.497.596.397C13.714 14.41 16 11.43 16 7.95 15.9 3.578 12.323 0 7.95 0z" />
                      </svg>
                      <span className="flex-auto pl-16 pr-8 -ml-16">
                        Continue with GitHub
                      </span>
                    </button>
                  </div>
                </div> */}
                <div className="flex flex-wrap -mx-3">
                  <div className="w-full px-3">
                    <button className="btn px-0 text-white bg-red-600 hover:bg-red-700 w-full relative flex items-center">
                      <svg
                        className="w-4 h-4 fill-current text-white opacity-75 flex-shrink-0 mx-4"
                        viewBox="0 0 16 16"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M7.9 7v2.4H12c-.2 1-1.2 3-4 3-2.4 0-4.3-2-4.3-4.4 0-2.4 2-4.4 4.3-4.4 1.4 0 2.3.6 2.8 1.1l1.9-1.8C11.5 1.7 9.9 1 8 1 4.1 1 1 4.1 1 8s3.1 7 7 7c4 0 6.7-2.8 6.7-6.8 0-.5 0-.8-.1-1.2H7.9z" />
                      </svg>
                      <span className="flex-auto pl-16 pr-8 -ml-16">
                        Continue with Google
                      </span>
                    </button>
                  </div>
                </div>
              </form>
              <div className="text-gray-600 text-center mt-6">
                Already using Simple?{" "}
                <Link
                  to="/signin"
                  className="text-blue-600 hover:underline transition duration-150 ease-in-out"
                >
                  Sign in
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default withRouter(SignUp);
