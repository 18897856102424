import React, { useState } from "react";
import fire from "../auth/fbAuth";
import { useToasts } from "react-toast-notifications";
import Meta from "../components/layout/meta/Meta";

function ResetPassword() {
  const [email, setEmail] = useState("");
  const { addToast } = useToasts();

  const forgotPassword = (e) => {
    e.preventDefault();

    if (email === "") {
      const message = "Cannot leave empty to sign in!";
      addToast(message, {
        appearance: "warning",
        autoDismiss: true,
      });
      return null;
    }

    fire
      .auth()
      .sendPasswordResetEmail(email)
      .then(function() {
        addToast("Reset email successfully sent", { appearance: "success", autoDismiss: true });
      })
      .catch(function(err) {
        // console.log("Reset email error!");
        const message = err.message;
        addToast(message, { appearance: "error", autoDismiss: true });
      });
    setEmail("");
  };

  return (
      <section className="bg-gradient-to-b from-gray-100 to-white">
      <Meta title="Reset password | KitaShare Web Application and OCR" />
        <div className="max-w-6xl mx-auto px-4 sm:px-6">
          <div className="pt-32 pb-12 md:pt-40 md:pb-20">
            {/* Page header */}
            <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
              <h1 className="h1 mb-4">Let’s get you back up on your feet</h1>
              <p className="text-xl text-gray-600">
                Enter the email address you used when you signed up for your
                account, and we’ll email you a link to reset your password.
              </p>
            </div>

            {/* Form */}
            <div className="max-w-sm mx-auto">
              <form onSubmit={forgotPassword}>
                <div className="flex flex-wrap -mx-3 mb-4">
                  <div className="w-full px-3">
                    <label
                      className="block text-gray-800 text-sm font-medium mb-1"
                      htmlFor="email"
                    >
                      Email <span className="text-red-600">*</span>
                    </label>
                    <input
                      id="email"
                      value={email}
                      aria-describedby="email-helper-text"
                      onChange={({ target }) => setEmail(target.value)}
                      type="email"
                      className="form-input w-full text-gray-800"
                      placeholder="Enter your email address"
                      required
                    />
                  </div>
                </div>
                <div className="flex flex-wrap -mx-3 mt-6">
                  <div className="w-full px-3">
                    <button
                      type="submit"
                      className="btn text-white bg-blue-600 hover:bg-blue-700 w-full"
                    >
                      Send reset link
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
  );
}

export default ResetPassword;
